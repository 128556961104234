import React, { useEffect, useState } from "react"

import { CheckCircleRounded, CloseRounded, DeleteRounded, ExpandMoreRounded } from "@mui/icons-material"
import {
  Accordion, AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography
} from "@mui/material"

import { addDoc, collection, doc, DocumentReference, onSnapshot } from "@firebase/firestore"
import { httpsCallable } from "@firebase/functions"
import { ref as storageRef, uploadBytes } from "@firebase/storage"
import { useFirebaseUser } from "../../firebase"
import { firebaseDatabase, firebaseFunctions, firebaseStorage } from "../../firebase/firebaseConfig"

import { useSnackbar } from "notistack"
import { FileUploader } from "react-drag-drop-files"

import { Collaborator } from "../../service"
import UserSelector from "../../userselector"

import { ConversationComponent, ConversationDetails, ConversationMessage } from "../service"

import ConversationMessages from "./ConversationMessages"

export default function Conversation (
  { handleCloseDrawer, id, projectID, scheduleID, subject, question }: ConversationComponent
) {
  const [attachments, setAttachments] = useState<FileList | null>()
  const [content, setContent] = useState<string>("")
  const [conversation, setConversation] = useState<ConversationDetails>({
    assignees: [],
    messages: []
  })
  const [conversationRef, setConversationRef] = useState<DocumentReference>()

  const { organization, role, user } = useFirebaseUser()
  const { enqueueSnackbar } = useSnackbar()

  const storageURL = `/organization/${organization?.id}/project/${projectID}/schedule/${scheduleID}/conversation/${id}/message`

  const handleAddCollaborator = async (email: string): Promise<boolean> => {
    if (!organization || !projectID || !id)
      return false

    const assignCollaborator = httpsCallable(firebaseFunctions, "assignCollaborator")

    return await assignCollaborator({
      email: email,
      organizationID: organization.id,
      path: [
        {collection: "organization", doc: organization.id},
        {collection: "project", doc: projectID},
        {collection: "schedule", doc: scheduleID},
        {collection: "conversation", doc: id}
      ]
    }).then(() => {
      return true
    }).catch((error) => {
      console.log(error)

      return false
    })
  }
  const handleContentChange = (e: { target: { value: React.SetStateAction<string> } }) =>
    setContent(e.target.value)
  const handleFileUploaderChange = (fileList: FileList) => {
    const newFiles = Array.from(fileList)

    setAttachments((prevAttachments) => {
      const prevFilesArray = prevAttachments ? Array.from(prevAttachments) : []
      const allFiles = [...prevFilesArray, ...newFiles]

      const dataTransfer = new DataTransfer()
      allFiles.forEach(file => dataTransfer.items.add(file))

      return dataTransfer.files
    })
  }
  const handleFileUploaderRemoveAttachment = (index: number) => {
    setAttachments((prevAttachments) => {
      if (!prevAttachments)
        return prevAttachments

      const prevFilesArray = Array.from(prevAttachments)
      const updatedFiles = prevFilesArray.filter((_, i) => i !== index)

      const dataTransfer = new DataTransfer()
      updatedFiles.forEach(file => dataTransfer.items.add(file))

      return dataTransfer.files
    })
  }
  const handleSubmit = () => {
    if (!conversationRef || !content || content === "" || !user)
      return

    const timestamp = new Date()

    addDoc(collection(conversationRef, "message"), {
      created: timestamp,
      content: content,
      sender: {
        email: user.email,
        photoURL: user.photoURL
      },
    }).then(async (message) => {
      setContent("")

      try {
        const uploadTasks = Array.from(attachments || []).map((attachment) => {
          return uploadBytes(
            storageRef(firebaseStorage, `${storageURL}/${message.id}/${attachment.name}`), attachment)
        })

        Promise.all(uploadTasks)
          .then(() => {
            setAttachments(null)

            enqueueSnackbar("Attachment Upload Complete!", { key: "message-sent", preventDuplicate: true, variant: "success" })
          })
      } catch(error) {
        return console.error("Error uploading attachments:", error)
      }

      setAttachments(null)
      setContent("")
      enqueueSnackbar("Message Sent!", { key: "message-sent", preventDuplicate: true, variant: "success" })
    }).catch((error) => {
      console.log(error)

      enqueueSnackbar("Something Went Wrong.", { key: "message-sent", preventDuplicate: true, variant: "error" })
    })
  }

  useEffect(() => {
    if (!organization || !projectID || !scheduleID || !id)
      return

    const organizationRef = doc(collection(firebaseDatabase, "organization"), organization?.id)

    const projectsRef = doc(collection(organizationRef, "project"), projectID)

    const scheduleRef = doc(collection(projectsRef, "schedule"), scheduleID)

    setConversationRef(doc(collection(scheduleRef, "conversation"), id))
  }, [id])

  useEffect(() => {
    if (!conversationRef)
      return

    onSnapshot(collection(conversationRef, "viewer"), async (result) => {
      const newCollaborators: Array<Collaborator> = []

      result.forEach((viewer) => {
        if (!viewer.exists())
          return

        const { email, photoURL } = viewer.data()

        const collaborator = {
          email,
          photoURL,
        } as Collaborator

        newCollaborators.push(collaborator)
      })

      setConversation((prevConversation) => ({
        ...prevConversation,
        assignees: newCollaborators
      }))
    })

    onSnapshot(collection(conversationRef, "message"), (result) => {
      const newMessages: Array<ConversationMessage> = []

      result.forEach((message) => {
        if (!message.exists())
          return

        const { created, content, sender } = message.data()

        const conversationMessage = {
          id: message.id,
          created,
          content,
          sender,
        } as ConversationMessage

        newMessages.push(conversationMessage)
      })

      setConversation((prevConversation) => ({
        ...prevConversation,
        messages: newMessages
      }))
    })
  }, [conversationRef])

  if (!conversation)
    return <Box alignItems="center" display="flex" height="100%" justifyContent="center">
      <CircularProgress size={150} />
    </Box>

  const { assignees, messages } = conversation

  return (
    <Box p={3}>
      <Box className="mb-4" display="flex" gap={2}>
        <IconButton onClick={handleCloseDrawer} title="Close Conversation">
          <CloseRounded />
        </IconButton>

        <Box>
          <Typography variant="h4">
            {subject}
          </Typography>

          <Typography variant="subtitle1">
            {question}
          </Typography>
        </Box>
      </Box>

      {
        role === "Managing Partner" && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography variant="h5">
                Collaborators
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UserSelector addCollaboratorCallback={handleAddCollaborator} collaborators={assignees} />
            </AccordionDetails>
          </Accordion>
        )
      }

      <ConversationMessages
        storageURL={storageURL}
        messages={messages}
      />

      <Box display="flex" flexDirection="column" gap={1}>
        <TextField
          fullWidth
          minRows={5}
          multiline
          onChange={handleContentChange}
          onKeyDown={(e) => {
            e.stopPropagation()
          }}
          required
          value={content}
          variant="filled"
        />

        <FileUploader
          classes="mw-100"
          handleChange={handleFileUploaderChange}
          multiple
          name="file"
          types={["PDF", "DOC", "DOCX", "JPG", "JPEG", "PNG", "GIF"]}
        />

        {
          attachments?.length === 0 ? (
            <Typography
              align="center"
              fontSize=".75rem"
              variant="subtitle1"
            >
              No Files Uploaded.
            </Typography>
          ) : (
            <List>
              {
                Array.from(attachments || []).map((file, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        onClick={() => handleFileUploaderRemoveAttachment(index)}
                        title={`delete ${file.name}`}
                      >
                        <DeleteRounded />
                      </IconButton>
                    }
                  >
                    <ListItemButton onClick={() => window.open(URL.createObjectURL(file), "_blank")}>
                      <ListItemText
                        primary={file.name}
                        secondary={
                          <Typography alignItems="center" display="flex" fontSize="0.75rem" color="success">
                            <CheckCircleRounded />
                            Attached
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))
              }
            </List>
          )
        }

        <Box display="flex" justifyContent="space-between" gap={2} width="100%">
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
          >
            Send Message
          </Button>
        </Box>
      </Box>
    </Box>
  )
}