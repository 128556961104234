import React, { useEffect, useState } from "react"

import {
  CheckRounded, CopyAllRounded, DoNotDisturbRounded,
  SendRounded
} from "@mui/icons-material"
import {
  Autocomplete,
  Avatar,
  Box,
  Button, CircularProgress, Container,
  createFilterOptions,
  IconButton,
  TextField,
  Typography
} from "@mui/material"
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid"


import { enqueueSnackbar } from "notistack"

import { PlaceholderUsers } from "../../PlaceholderData"

import { Collaborator } from "../service"

const filter = createFilterOptions<Collaborator>()

export default function UserSelector(
  { addCollaboratorCallback, collaborators = [], canAssignEditable = false, editCollaboratorPermissionCallback }:
    {
      addCollaboratorCallback: (email: string) => Promise<boolean>,
      collaborators?: Array<Collaborator>,
      canAssignEditable?: boolean
      editCollaboratorPermissionCallback?: (email: string, canEdit: boolean) => Promise<boolean>,
    }
) {
  const [isUserEditLoading, setIsUserLoading] = useState<Record<string, boolean>>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [key, setKey] = useState<string>()
  const [options, setOptions] = useState<Array<Collaborator>>([])
  const [rows, setRows] = useState<Array<{edit: boolean, email: string, photoURL: string, id: string}>>([])
  const columns: GridColDef<Collaborator>[] = [
    {
      field: "id",
      flex: 1,
      headerName: "Collaborator",
      renderCell: (params) => {
        const { row } = params

        return (
          <Box alignItems="center" display="flex" gap={1}>
            <Avatar alt={row.email} src={row.photoURL} title={row.email}/>
            <Typography className="ml-1" variant="body1">
              {row.email}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: "edit",
      flex: .3,
      headerName: "Edit",
      renderCell: ({ row, value }) => {
        return isUserEditLoading[row.email] ? (
          <CircularProgress size={30} />
        ) : (
          value ? (
            <CheckRounded color="success" />
          ) : (
            <DoNotDisturbRounded color="error" />
          )
        )
      }
    },
    {
      field: "actions",
      flex: .3,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<SendRounded />}
          label="notify"
          title={`Notify ${row.email}`}
          onClick={() => handleNotify(row.email)}
        />
      ],
      headerName: "",
      renderHeader: () => (
        rows.length > 1 && (
          <IconButton
            onClick={() => handleNotify()}
            size="small"
            title={`Notify all collaborators.`}
          >
            <SendRounded />
          </IconButton>
        )
      ),
      type: "actions"
    }
  ]

  const handleAddCollaborator = async (collaborator: Collaborator) => {
    setIsLoading(true)

    const email = collaborator.email.replace("Add ", "")

    const success = await addCollaboratorCallback(email)

    if (success) {
      setKey(email)

      enqueueSnackbar(`Added ${email} as a collaborator.`, {variant: "success"})
    } else {
      enqueueSnackbar(`Something went wrong.`, {variant: "error"})
    }

    setIsLoading(false)
  }
  const handleToggleEditPermission = async (collaborator: Collaborator) => {
    if (!editCollaboratorPermissionCallback)
      return

    const { email, edit } = collaborator

    setIsUserLoading({
      ...isUserEditLoading,
      [email]: true
    })

    const success = await editCollaboratorPermissionCallback(email, !edit)

    if (success) {
      const newRows = [...rows]

      let collaborator = newRows.find(collaborator => collaborator.email === email)

      if (collaborator) {
        collaborator.edit = edit

        setRows(newRows)
      }

      setKey(email)

      enqueueSnackbar(`Set ${email} as ${!edit ? "an editor" : "a viewer"}.`, {variant: "success"})
    } else {
      enqueueSnackbar(`Something went wrong.`, {variant: "error"})
    }

    setIsUserLoading({
      ...isUserEditLoading,
      [email]: false
    })

  }
  const handleAssigneeCopyToClipboard = (collaborator?: Collaborator) => {
    if (collaborator) {
      enqueueSnackbar(`${collaborator.email} copied to your clipboard.`, {variant: "success"})
      navigator.clipboard.writeText(Array.from(rows.map(row => row.email)).join(", "))
    }
    else {
      enqueueSnackbar(`${rows.length} email addresses copied to your clipboard.`, {variant: "success"})
      navigator.clipboard.writeText(Array.from(rows.map(row => row.email)).join(", "))
    }
  }
  const handleCellClick = ({ field, row }: GridCellParams) => {
    if (field === "id")
      handleAssigneeCopyToClipboard(row)
    else if (field === "edit")
      handleToggleEditPermission(row)
  }
  const handleNotify = (email?: string) => {
    enqueueSnackbar(`Successfully notified ${email ? email : `${rows.length} collaborators`}.`, {variant: "success"})
  }

  useEffect(() => {
    setOptions(PlaceholderUsers.map(user => {
      if (!canAssignEditable)
        return user

      return {
        ...user,
        edit: false
      }
    }))
  }, [canAssignEditable])

  useEffect(() => {
    if (collaborators)
      setRows(collaborators.map(assignee => ({
        id: assignee.email,
        ...assignee
      })))
  }, [collaborators])

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" gap={2}>
        <Autocomplete
          className="w-100"
          clearOnBlur
          clearOnEscape
          disableCloseOnSelect
          disableClearable
          filterOptions={(options, params) => {
            const filtered = filter(options, params)

            const { inputValue } = params

            const isExisting = options.some((option) => inputValue === option.email)

            if (inputValue !== '' && !isExisting && /^\S+@\S+\.\S+$/.test(inputValue)) {
              filtered.push({
                edit: false,
                email: `Add ${inputValue}`,
                photoURL: ""
              })
            }

            return filtered
          }}
          getOptionLabel={(option) => {
            return option.email
          }}
          key={key}
          loading={isLoading}
          noOptionsText="Collaborator not found."
          onChange={(event, collaborator) => handleAddCollaborator(collaborator)}
          options={options}
          renderInput={(params) => (
            <TextField {...params} placeholder="Add Collaborators" variant="filled" />
          )}
        />

        <DataGrid
          autoHeight
          className="w-100"
          columns={columns}
          columnVisibilityModel={{ edit: canAssignEditable }}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          hideFooter
          onCellClick={handleCellClick}
          rows={rows}
          slots={{
            noRowsOverlay: () => (
              <Typography
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center"
                }}
                variant="subtitle1"
              >
                No Collaborators
              </Typography>
            )
          }}
        />

        <Button className="m-auto" onClick={() => handleAssigneeCopyToClipboard()} variant="contained">
          <CopyAllRounded /> Copy Emails to Clipboard
        </Button>
      </Box>
    </Container>
  )
}
