import React, { useEffect, useState } from "react"

import {
  ArrowForwardIosRounded,
  CheckRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  SendRounded
} from "@mui/icons-material"
import { Box, Button, Container, Dialog, IconButton, Typography } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid"

import { useFirebaseUser } from "../firebase"

import ProjectStatusChip from "../projectstatuschip"
import Schedule from "../schedule"
import AddProject from "./addproject"

import { ProjectDetailsDatagridRow } from "./service"
import { ScheduleOverviewMutable } from "../schedule/service"

export default function Projects() {
  const [rows, setRows] = useState<Array<ProjectDetailsDatagridRow>>([])
  const [isProjectAddOpen, setIsProjectAddOpen] = useState<boolean>(false)

  const { projects, role, scheduleSelected, setScheduleSelected } = useFirebaseUser()

  const handleProjectAddOpen = () => setIsProjectAddOpen(true)
  const handleProjectAddClose = () => setIsProjectAddOpen(false)

  const columns: GridColDef<ProjectDetailsDatagridRow>[] = [
    {
      field: "id",
      flex: 1,
      headerName: "",
      renderCell: (params) => {
        const { row } = params

        return row.isProject ? (
          <Box display="flex" alignItems="center">
            <IconButton
              className="mr-2"
              disabled={Boolean(row.isExpandDisabled)}
              onClick={() => handleRowExpand(row.id)}
              size="small"
            >
              {row.isExpanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            </IconButton>
          </Box>
        ) : ""
      },
      sortable: false
    },
    {
      field: "name",
      flex: 1,
      headerName: "Project",
      renderCell: ({ row, value }) => {
        return row.isProject ? (
          <Typography fontSize="1.15rem">
            {value}
          </Typography>
        ) : (
          <Typography fontSize="0.85rem" variant="subtitle1">
            {value}
          </Typography>
        )
      }
    },
    {
      align: "center",
      field: "disclosure",
      flex: 1,
      headerName: "Disclosure Required",
      renderCell: ({ row, value }) => {
        return !row.isProject && value && (
          <CheckRounded color="success" />
        )
      }
    },
    {
      field: "status",
      flex: 1,
      headerName: "Status",
      renderCell: ({ row, value }) => <ProjectStatusChip isOnlyIcon={!row.isProject} status={value} />
    },
    {
      field: "actions",
      flex: 1,
      getActions: (params) => {
        const { row } = params

        return !row.isProject ? ([
          <GridActionsCellItem
            icon={<ArrowForwardIosRounded />}
            label="View"
            onClick={() => handleScheduleSelect(row)}
            style={{
              background: row.id === scheduleSelected?.id ? "rgba(144, 94, 255, 0.50)" : ""
            }}
            title={`View ${row.name}`}
          />
        ]) : ([
          <GridActionsCellItem
            hidden
            icon={<SendRounded />}
            label="Send"
            style={{
              background: row.id === scheduleSelected?.id ? "rgba(144, 94, 255, 0.50)" : ""
            }}
          />
        ])
      },
      headerName: "",
      renderHeader: () => (
        role === "Managing Partner" && (
          <Button onClick={handleProjectAddOpen} variant="contained">
            New Project
          </Button>
        )
      ),
      type: "actions"
    }
  ]

  const getVisibleRows = () => {
    let visibleRows: any[] = []

    rows.filter(row => row.isProject).forEach((row) => {
      visibleRows.push(row)

      if (row.isProject && row.isExpanded) {
        const childRows = rows.filter((child) => child.projectID === row.id)
        visibleRows = [...visibleRows, ...childRows]
      }
    })

    return visibleRows
  }
  const handleRowExpand = (id: string) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id
          ? { ...row, isExpanded: !row.isExpanded }
          : { ...row, isExpanded: false }
      )
    )
  }
  const handleScheduleSelect = (schedule: ProjectDetailsDatagridRow) => setScheduleSelected(schedule)

  useEffect(() => {
    if (!projects)
      return

    const newRows = Object.values(projects).flatMap((project) => {
      const projectRow = {
        ...project,
        id: project.id,
        isExpandDisabled: project.schedules.length === 0,
        isProject: true
      }

      const scheduleRows = project.schedules.map((schedule: ScheduleOverviewMutable, index, array) => ({
        ...schedule,
        name: `${schedule.id} ${schedule.name}`,
        projectID: project.id,
        projectName: project.name,
        previousScheduleID: index > 0 ? array[index - 1].id : null,
        nextScheduleID: index < array.length - 1 ? array[index + 1].id : null,
      }))

      return [projectRow, ...scheduleRows]
    }) as Array<ProjectDetailsDatagridRow>

    setRows(newRows)
  }, [projects])

  if (scheduleSelected)
    return <Schedule {...scheduleSelected} schedules={rows.filter(row => row.projectID)} />

  return (
    <Container maxWidth="md">
      <DataGrid
        autoHeight
        columns={columns}
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
        hideFooter
        initialState={{
          // @ts-ignore
          rowGrouping: {
            model: ["name"],
          }
        }}
        pageSize={10}
        rows={getVisibleRows()}
        rowsPerPageOptions={[10]}
        slots={{
          noRowsOverlay: () => (
            <Typography
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center"
              }}
              variant="subtitle1"
            >
              No Projects
            </Typography>
          )
        }}
      />

      <Dialog
        onClose={handleProjectAddClose}
        open={isProjectAddOpen}
        PaperProps={{
          sx: { width: "50%" },
        }}
      >
        <AddProject handleClose={handleProjectAddClose} />
      </Dialog>
    </Container>
  )
}