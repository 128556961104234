import { closeSnackbar, enqueueSnackbar } from "notistack"
import React, { useEffect, useState } from "react"

import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded, CheckRounded, DoNotDisturbRounded,
  ExpandMoreRounded,
  OpenInBrowserRounded,
  ReportRounded
} from "@mui/icons-material"
import {
  Accordion, AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress, Dialog, DialogActions,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@mui/material"
import Grid from "@mui/material/Grid2"

import { collection, doc, DocumentReference, getDoc, getDocs, onSnapshot, Unsubscribe } from "@firebase/firestore"
import { httpsCallable } from "@firebase/functions"
import { useFirebaseUser } from "../firebase"
import { firebaseDatabase, firebaseFunctions } from "../firebase/firebaseConfig"

import Conversations from "../conversations"
import { ProjectDetailsDatagridRow } from "../projects/service"
import ProjectStatusChip from "../projectstatuschip"
import UserSelector from "../userselector"

import { ScheduleDetailsMutable, ScheduleFile, ScheduleOverviewMutableLinked, Term } from "./service"
import { Collaborator } from "../service"
import { ConversationOverview } from "../conversations/service"

import { ScheduleDetails } from "./serviceImpl"
import { LoadingButton } from "@mui/lab"

export default function Schedule(
  { disclosure, id, name, nextScheduleID, previousScheduleID, projectID, schedules, status }: ScheduleOverviewMutableLinked) {

  const [isReportLoading, setIsReportLoading] = useState<boolean>(false)
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false)
  const [schedule, setSchedule] = useState<ScheduleDetails>({
    collaboratorEditors: [],
    collaboratorViewers: [],
    conversations: [],
    draft: "",
    files: [],
    paText: "",
    terms: []
  })
  const [scheduleRef, setScheduleRef] = useState<DocumentReference>()
  const [listeners, setListeners] = useState<Array<Unsubscribe>>()
  const [newSchedule, setNewSchedule] = useState<ScheduleDetailsMutable>()
  const [next, setNext] = useState<ProjectDetailsDatagridRow>()
  const [previous, setPrevious] = useState<ProjectDetailsDatagridRow>()

  const { organization, role, setScheduleSelected } = useFirebaseUser()

  const handleAddCollaborator = async (email: string): Promise<boolean> => {
    if (!organization || !projectID || !id)
      return false

    const assignCollaborator = httpsCallable(firebaseFunctions, "assignCollaborator")

    return await assignCollaborator({
      email: email,
      organizationID: organization.id,
      path: [
        {collection: "organization", doc: organization.id},
        {collection: "project", doc: projectID},
        {collection: "schedule", doc: id},
      ]
    }).then(() => {
      return true
    }).catch((error) => {
      console.log(error)

      return false
    })
  }
  const handleEditCollaboratorPermission = async (email: string, canEdit: boolean): Promise<boolean> => {
    if (!organization || !projectID || !id)
      return false

    const assignCollaboratorPermissions = httpsCallable(firebaseFunctions, "assignCollaboratorPermissions")

    return await assignCollaboratorPermissions({
      email: email,
      isEdit: canEdit,
      path: [
        {collection: "organization", doc: organization.id},
        {collection: "project", doc: projectID},
        {collection: "schedule", doc: id},
      ]
    }).then(() => {
      return true
    }).catch((error) => {
      console.log(error)

      return false
    })
  }
  const handleGetFiles = () => {
    if (!scheduleRef || !schedule?.collaboratorViewers || schedule?.files.length > 0)
      return

    getDocs(collection(scheduleRef, "file")).then((result) => {
      const newFiles: Array<ScheduleFile> = []

      result.forEach((file) => {
        const fileData = file.data()

        newFiles.push({
          name: fileData.name,
        })
      })

      setSchedule({
        ...schedule,
        files: newFiles
      })
    })
  }
  const handleGetTerms = () => {
    if (!scheduleRef || !schedule?.collaboratorViewers || schedule?.terms.length > 0)
      return

    getDocs(collection(scheduleRef, "term")).then((result) => {
      const newTerms: Array<Term> = []

      result.forEach((term) => {
        const termData = term.data()

        newTerms.push({
          name: termData.name,
          definition: termData.definition,
        })
      })

      setSchedule({
        ...schedule,
        terms: newTerms
      })
    })
  }
  const handleNewScheduleClear = () => setNewSchedule(undefined)
  const handleNewScheduleSubmit = () => {
    if (!organization || !projectID || !id)
      return

    setIsUpdateLoading(true)

    const updateSchedule = httpsCallable(firebaseFunctions, "updateSchedule")

    updateSchedule({
      organizationID: organization.id,
      projectID: projectID,
      scheduleID: id,
      newSchedule: newSchedule
    }).then((result) => {
      if (result.data) {
        setSchedule({
          ...schedule,
          ...newSchedule
        })
        handleNewScheduleClear()
        closeSnackbar("report")
        enqueueSnackbar(`Updated section.`, {
          key: "report",
          preventDuplicate: true,
          variant: "success"
        })
      }

    }).finally(() => {
      setIsUpdateLoading(false)
    })
  }
  const handleNewScheduleTextChange = (event: { target: { value: string } }) =>
    setNewSchedule({
      disclosure: newSchedule?.disclosure || disclosure,
      name: newSchedule?.name || name,
      paText: event.target.value,
      status: newSchedule?.status || status
    })
  const handleNewScheduleTitleChange = (event: { target: { value: string } }) =>
    setNewSchedule({
      disclosure: newSchedule?.disclosure || disclosure,
      name: event.target.value,
      paText: newSchedule?.paText || schedule.paText,
      status: newSchedule?.status || status
    })
  const handleNewScheduleDisclosureToggle = () =>
    setNewSchedule({
      disclosure: !newSchedule?.disclosure,
      name: newSchedule?.name || name,
      paText: newSchedule?.paText || schedule.paText,
      status: newSchedule?.status || status
    })
  const handleReportIncorrectSectionText = async (event: React.MouseEvent<HTMLElement>) => {
    if (!organization || !projectID || !id)
      return

    event.stopPropagation()

    setIsReportLoading(true)

    enqueueSnackbar(`Re-parsing section. Do not exit this page.`, {
      autoHideDuration: 5000,
      key: "report",
      preventDuplicate: true,
      variant: "info"
    })

    const reportIncorrectSection = httpsCallable(firebaseFunctions, "reportIncorrectSection")

    await reportIncorrectSection({
      organizationID: organization.id,
      projectID: projectID,
      scheduleID: id
    }).then((result) => {
      const { content, disclosure_schedule, error, errorType, section_title } = result.data as {
        content: string,
        disclosure_schedule: Boolean,
        error: Boolean,
        errorType: string,
        section_title: string
      }

      closeSnackbar("report")

      if (error) {
        console.log(error, errorType)

        enqueueSnackbar(`Something Went Wrong: ${errorType}`, {
          key: "report",
          preventDuplicate: true,
          variant: "error"
        })
      }

      enqueueSnackbar("Awaiting your verification of updated section text.", {
        key: "report",
        preventDuplicate: true,
        variant: "info"
      })

      setNewSchedule({
        disclosure: disclosure_schedule || disclosure,
        name: section_title,
        paText: content,
        status: newSchedule?.status || status
      })
    }).catch((error) => {
      console.log(error)

      closeSnackbar("report")
      enqueueSnackbar("Something went wrong while re-parsing.", {
        key: "report",
        preventDuplicate: true,
        variant: "error"
      })
    })

    setIsReportLoading(false)
  }
  const handleScheduleNext = () =>
    setScheduleSelected((prevScheduleSelected: ScheduleOverviewMutableLinked) => next || prevScheduleSelected)
  const handleSchedulePrevious = () =>
    setScheduleSelected((prevScheduleSelected: ScheduleOverviewMutableLinked) => previous || prevScheduleSelected)

  useEffect(() => {
    if (!organization || !projectID || !id)
      return

    const organizationRef = doc(firebaseDatabase, "organization", organization.id)

    const projectRef = doc(organizationRef, "project", projectID)

    setScheduleRef(doc(projectRef, "schedule", id))
  }, [id])

  useEffect(() => {
    if (!scheduleRef)
      return

    getDoc(scheduleRef).then(async (result) => {
      const data = result.data()

      const newSchedule = new ScheduleDetails({
        collaboratorEditors: [],
        collaboratorViewers: [],
        files: [],
        paText: data?.paText,
        terms: [],
        conversations: [],
        draft: data?.draft
      })

      setSchedule(newSchedule)

      const unsubscribeCollaboratorViewer = onSnapshot(collection(scheduleRef, "viewer"), (result) => {
        const newViewers: Array<Collaborator> = []

        result.forEach((viewer) => {
          const viewerData = viewer.data()

          newViewers.push({
            edit: false,
            email: viewerData.email,
            photoURL: viewerData.photoURL
          })
        })

        setSchedule((prevSchedule) => ({
          ...prevSchedule,
          collaboratorViewers: newViewers
        }))
      })

      const unsubscribeCollaboratorEditor = onSnapshot(collection(scheduleRef, "editor"), (result) => {
        const newEditors: Array<Collaborator> = []

        result.forEach((editor) => {
          const editorData = editor.data()

          newEditors.push({
            edit: true,
            email: editorData.email,
            photoURL: editorData.photoURL
          })
        })

        setSchedule((prevSchedule) => ({
          ...prevSchedule,
          collaboratorEditors: newEditors
        }))
      })

      const unsubscribeConversations = onSnapshot(collection(scheduleRef, "conversation"), (result) => {
        const newConversations: Array<ConversationOverview> = []

        result.forEach((conversation) => {
          const conversationData = conversation.data()

          newConversations.push({
            id: conversation.id,
            question: conversationData.question,
            subject:  conversationData.subject,
            status:  conversationData.status
          } as ConversationOverview)
        })

        setSchedule((prevSchedule) => ({
          ...prevSchedule,
          conversations: newConversations
        }))
      })

      setListeners([
        unsubscribeCollaboratorEditor,
        unsubscribeCollaboratorViewer,
        unsubscribeConversations
      ])
    }).catch((error) => {
      console.log(scheduleRef.path, error)
    })
  }, [scheduleRef])

  useEffect(() => {
    if (id && listeners)
      listeners.map(listener => listener())
  }, [id])

  useEffect(() => {
    if (!schedules)
      return

    setNext(schedules.find(curSchedule => curSchedule.id === nextScheduleID))
    setPrevious(schedules.find(curSchedule => curSchedule.id === previousScheduleID))
  }, [next, previous, schedules])

  return <Box display="flex" flexDirection="column" gap={2}>
    {
      schedule ? (
        <Box>
          <Grid container spacing={2}>
            <Grid alignItems="center" display="flex" gap={2} size={{ xs: 12, md: 6 }}>
              <IconButton disabled={!Boolean(previous)} onClick={handleSchedulePrevious} title={previous?.name}>
                <ArrowBackIosRounded />
              </IconButton>

              <Typography fontSize="3rem" fontWeight={600}>
                {name}
              </Typography>
            </Grid>

            <Grid alignItems="center" display="flex" gap={1} justifyContent="flex-end" size={{ xs: 12, md: 6 }}>
              <ProjectStatusChip status={status} />

              <IconButton
                className="m-2"
                disabled={isReportLoading}
                onClick={handleReportIncorrectSectionText}
                title="Report inaccurate content."
              >
                {
                  isReportLoading
                    ? <CircularProgress size={20} />
                    : <ReportRounded />
                }
              </IconButton>

              <IconButton disabled={!Boolean(next)} onClick={handleScheduleNext} title={next?.name}>
                <ArrowForwardIosRounded />
              </IconButton>

            </Grid>

            <Grid display="flex" flexDirection="column" gap={2} size={{ xs: 12, md: 8 }}>
              <Paper>
                <Box display="flex" flexDirection="column" gap={2} p={2}>
                  <Typography variant="h5">
                    Schedule Draft
                  </Typography>

                  <TextField rows={20} multiline value={schedule.draft} variant="filled" />

                  <Grid container>
                    <Grid size={{ xs:8, md:10 }}>
                      Footnotes
                    </Grid>
                    <Grid size={{ xs:4, md:2 }}>
                      <Button className="w-25" variant="contained">
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>

              {
                projectID && id && (
                  <Conversations conversations={schedule.conversations} projectID={projectID} scheduleId={id} />
                )
              }
            </Grid>

            <Grid display="flex" flexDirection="column" gap={2} size={{ xs: 12, md: 4 }}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                  <Box alignItems="center" display="flex" justifyContent="space-between" marginRight={1} width="100%">
                    <Typography variant="h5">
                      Purchase Agreement
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    component={"p"}
                    disabled
                    maxRows={22}
                    multiline
                    value={schedule.paText.replaceAll("\\n", "\n")}
                    variant="filled"
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion onClick={handleGetFiles}>
                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                  <Typography variant="h5">
                    Relevant Files
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column" gap={1} maxHeight={550} overflow="auto" px={1}>
                    {
                      schedule.files.map(file => (
                        <Paper>
                          <Box alignItems="center" display="flex" gap={2} justifyContent="space-between" p={2}>
                            <Typography variant="h5">
                              {file.name}
                            </Typography>

                            <IconButton title={`View ${file.name}`}>
                              <OpenInBrowserRounded />
                            </IconButton>
                          </Box>
                        </Paper>
                      ))
                    }
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion onClick={handleGetTerms}>
                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                  <Typography variant="h5">
                    Defined Terms
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column" gap={1} maxHeight={550} overflow="auto" px={1}>
                    {
                      schedule.terms.map(term => (
                        <Paper>
                          <Box display="flex" flexDirection="column" gap={1} p={2}>
                            <Typography fontWeight={600}>
                              {term.name}
                            </Typography>

                            <Typography variant="subtitle1">
                              {term.definition}
                            </Typography>
                          </Box>
                        </Paper>
                      ))
                    }
                  </Box>
                </AccordionDetails>
              </Accordion>

              {
                role === "Managing Partner" && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                      <Typography variant="h5">
                        Collaborators
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" flexDirection="column" gap={1} maxHeight={550} overflow="auto" px={1}>
                        <UserSelector
                          addCollaboratorCallback={handleAddCollaborator}
                          collaborators={[...schedule.collaboratorEditors, ...schedule.collaboratorViewers]}
                          canAssignEditable
                          editCollaboratorPermissionCallback={handleEditCollaboratorPermission}
                        />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )
              }
            </Grid>
          </Grid>

          <Dialog
            open={Boolean(newSchedule)}
            PaperProps={{
              sx: { maxWidth: "75vw" },
            }}
          >
            <Box display="flex" gap={4} minHeight="50vh" minWidth="75vw" p={3}>
              <Box display="flex" flexDirection="column" gap={2} width="100%">
                <Typography variant="h4">
                  Current
                </Typography>

                <Typography variant="h5">
                  Title
                </Typography>
                <TextField
                  component={"p"}
                  disabled
                  maxRows={2}
                  multiline
                  rows={2}
                  value={name}
                  variant="filled"
                />

                <Box display="flex" gap={2}>
                  <Typography variant="h5">
                    Disclosure Required:
                  </Typography>

                  {
                    disclosure ? (
                      <CheckRounded color="success"/>
                    ) : (
                      <DoNotDisturbRounded color="error"/>
                    )
                  }
                </Box>

                <Typography variant="h5">
                  Purchase Agreement Text
                </Typography>
                <TextField
                  component={"p"}
                  disabled
                  maxRows={22}
                  multiline
                  value={schedule.paText.replaceAll("\\n", "\n")}
                  variant="filled"
                />
              </Box>

              <Box display="flex" flexDirection="column" gap={2} width="100%">
                <Typography variant="h4">
                  New
                </Typography>

                <Typography variant="h5">
                  Title
                </Typography>
                <TextField
                  component={"p"}
                  maxRows={2}
                  multiline
                  onChange={handleNewScheduleTitleChange}
                  rows={2}
                  value={newSchedule?.name}
                  variant="filled"
                />

                <Box display="flex" gap={2}>
                  <Typography variant="h5">
                    Disclosure Required:
                  </Typography>

                  <Box display="flex" gap={2} onClick={handleNewScheduleDisclosureToggle} style={{ cursor: "pointer" }}>
                    {
                      newSchedule?.disclosure ? (
                        <CheckRounded color="success"/>
                      ) : (
                        <DoNotDisturbRounded color="error"/>
                      )
                    }
                  </Box>
                </Box>

                <Typography variant="h5">
                  Purchase Agreement Text
                </Typography>
                <TextField
                  className="h-100"
                  component={"p"}
                  maxRows={22}
                  multiline
                  onChange={handleNewScheduleTextChange}
                  value={newSchedule?.paText.replaceAll("\\n", "\n")}
                  variant="filled"
                />
              </Box>
            </Box>

            <DialogActions>
              <Button color="error" onClick={handleNewScheduleClear}>
                Cancel
              </Button>
              <LoadingButton
                disabled={isUpdateLoading}
                loading={isUpdateLoading}
                onClick={handleNewScheduleSubmit}
                variant="contained"
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Box>
      ) : (
        <Box
          alignItems="center"
          display="flex"
          height="90vh"
          justifyContent="center"
        >
          <CircularProgress size={100} />
        </Box>
      )
    }
  </Box>
}