import React from "react"

import { Container } from "@mui/material"
import Administrator from "../components/administrator"

import PageLogin from "./PageLogin"
import FullDisclosureAppBar from "../components/appbar"
import {useFirebaseUser} from "../components/firebase"
import Projects from "../components/projects"

export default function PageHome() {
  const { user, role } = useFirebaseUser()

  if (!user)
    return <PageLogin />

  return (
    <Container maxWidth="xl">
      <FullDisclosureAppBar />

      {
        role === "Administrator" ? (
          <Administrator />
        ) : (
          <Projects />
        )
      }
    </Container>
  )
}