import React, { useEffect, useState } from "react"

import { OpenInBrowserRounded } from "@mui/icons-material"
import { Box, IconButton, ImageList, ImageListItem, ImageListItemBar, Modal, Paper, Typography } from "@mui/material"

import { getDownloadURL, listAll, ref } from "@firebase/storage"
import { firebaseStorage } from "../../firebase/firebaseConfig"

import { ConversationMessageImagesComponent } from "../service"

export default function ConversationMessageImages ({ id, storageURL }: ConversationMessageImagesComponent) {
  const [attachments, setAttachments] = useState<Array<HTMLEmbedElement>>([])
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

  const handleImageClick = (attachmentIndex: number) => setExpandedIndex(attachmentIndex)
  const handleClose = () => setExpandedIndex(null)

  useEffect(() => {
    listAll(ref(firebaseStorage, `${storageURL}/${id}`)).then(async (res) => {
      const { items } = res

      setAttachments(
        await Promise.all(
          items.map((item) => getDownloadURL(item).then((url) => {
            const name = item.name

            return { name, src: url, type: name.split('.').pop() || '' } as HTMLEmbedElement
          }))
        )
      )
    })
  }, [id, storageURL])

  return (
    <>
      <ImageList
        gap={16}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {
          attachments.map((attachment, attachmentIndex) =>
            ["gif", "png", "jpg", "jpeg"].includes(attachment.type) ? (
              <ImageListItem key={attachmentIndex} onClick={() => handleImageClick(attachmentIndex)}>
                <img alt={attachment.name || `Attachment ${attachmentIndex + 1}`} src={attachment.src} />
                <ImageListItemBar title={attachment.name || `Attachment ${attachmentIndex + 1}`} />
              </ImageListItem>
            ) : ["pdf", "doc", "docx"] && (
              <Paper>
                <Box alignItems="center" display="flex" gap={2} justifyContent="space-between" p={2}>
                  <Typography variant="h5">
                    {attachment.name}
                  </Typography>

                  <IconButton title={`View ${attachment.name}`} onClick={() => window.open(attachment.src, "_blank")}>
                    <OpenInBrowserRounded />
                  </IconButton>
                </Box>
              </Paper>
            )
          )
        }
      </ImageList>
      {
        expandedIndex !== null && (
          <Modal
            open
            onClose={handleClose}
            aria-labelledby="expanded-image"
            aria-describedby="expanded-image"
            closeAfterTransition
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "90vw",
                maxHeight: "90vh",
              }}
            >
              <img
                alt={attachments[expandedIndex]?.name || `Attachment ${expandedIndex + 1}`}
                src={attachments[expandedIndex]?.src}
                style={{ maxWidth: "90vw" }}
              />
            </Box>
          </Modal>
        )
      }
    </>
  )
}