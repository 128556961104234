import React, { useEffect, useState } from "react"

import {
  AppBar,
  Box,
  Breadcrumbs,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
} from "@mui/material"
import Grid from "@mui/material/Grid2"
import { ExpandLessRounded, ExpandMoreRounded, HomeRounded, NavigateNextRounded } from "@mui/icons-material"

import { useFirebaseUser } from "../firebase"

import UserDropdown from "../userdropdown"

import { ScheduleOverviewMutable } from "../schedule/service"

export default function FullDisclosureAppBar() {
  const [availableSchedules, setAvailableSchedules] = useState<Array<ScheduleOverviewMutable>>()
  const [availableSchedulesAnchorEl, setAvailableSchedulesAnchorEl] = useState<null | HTMLElement>(null)

  const { isOrganizationLoading, organization, projects, scheduleSelected, setScheduleSelected } = useFirebaseUser()

  const handleScheduleProjectClick = () => setScheduleSelected()
  const handleScheduleSelectorOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAvailableSchedulesAnchorEl(event.currentTarget)
  const handleScheduleSelectorClose = () => setAvailableSchedulesAnchorEl(null)
  const handleScheduleSelectorSelect = (schedule: ScheduleOverviewMutable) => {
    handleScheduleSelectorClose()
    setScheduleSelected(schedule)
  }
  const handleClearScheduleSelected = () => setScheduleSelected(null)

  useEffect(() => {
    if (projects && scheduleSelected?.projectID) {
      const project = projects.find(project => project.id === scheduleSelected.projectID)

      setAvailableSchedules(
        project?.schedules.map((schedule: ScheduleOverviewMutable) => ({
          ...schedule,
          name: `${schedule.id} ${schedule.name}`,
          projectID: project.id,
          projectName: project.name
        })))
    }
  }, [projects, scheduleSelected])

  const noOrganization = !organization && !isOrganizationLoading

    return (
    <AppBar className="mb-5 mt-3" position="static">
      <Toolbar>
        <Grid alignItems="center" container width="100%">
          <Grid alignItems="center" display="flex" gap={1} size={{ xs: 6, md: 5 }}>
            {
              scheduleSelected && (
                <Box display="flex">
                  <Breadcrumbs className="d-flex" separator={<NavigateNextRounded fontSize="small" />}>
                    <IconButton disabled={!scheduleSelected} onClick={handleClearScheduleSelected}>
                      <HomeRounded />
                    </IconButton>

                    <Chip
                      label={scheduleSelected.projectName}
                      onClick={() => handleScheduleProjectClick()}
                    />

                    <Chip
                      label={
                        <Box>
                          {scheduleSelected?.name}
                          {
                            availableSchedulesAnchorEl ? (
                              <ExpandLessRounded />
                            ) : (
                              <ExpandMoreRounded />
                            )
                          }
                        </Box>
                      }
                      onClick={handleScheduleSelectorOpen}
                    />
                  </Breadcrumbs>

                  {
                    availableSchedules && (
                      <Menu
                        anchorEl={availableSchedulesAnchorEl}
                        open={Boolean(availableSchedulesAnchorEl)}
                        onClose={handleScheduleSelectorClose}
                      >
                        {
                          availableSchedules.map((schedule) => (
                            <MenuItem key={schedule.id} onClick={() => handleScheduleSelectorSelect(schedule)}>
                              {schedule.name}
                            </MenuItem>
                          ))
                        }
                      </Menu>
                    )
                  }
                </Box>
              )
            }
          </Grid>

          <Grid justifyContent="center" size={{ xs: 0, md: 2 }} sx={{ display: { xs: "none", md: "inherit" } }}>
            {
              !organization ? (
                noOrganization ? (
                  "Full Disclosure"
                ) : (
                  <Skeleton height={60} width={200} />
                )
              ) : organization.logoURL && (
                <img alt={`${organization.name} Logo`} height={55} src={organization.logoURL} />
              )
            }
          </Grid>

          <Grid size={{ xs: 6, md: 5 }}>
            <UserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}