import { GridApiCommunity } from "@mui/x-data-grid/internals"
import { useEffect, useState } from "react"
import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  GridColDef,
  DataGrid,
  GridCellModes,
  GridEventListener,
  GridCellModesModel,
  GridSlots, GridColumnVisibilityModel, useGridApiRef
} from "@mui/x-data-grid"

interface EditToolbarProps {
  cellModesModel: GridCellModesModel
  setCellModesModel: (value: GridCellModesModel) => void
  cellMode: 'view' | 'edit'
  columns: GridColDef[]
  rows: Array<any>
}

interface ApiRefEditToolbarProps extends EditToolbarProps {
  apiRef: GridApiCommunity
}

function EditToolbar(props: ApiRefEditToolbarProps) {
  const { cellMode, cellModesModel, setCellModesModel, columns, rows } = props

  const handleSaveOrEdit = () => {
    const isSave = cellMode === 'edit'

    const newMode = isSave ? GridCellModes.View : GridCellModes.Edit

    const updatedModel = rows.reduce((acc, row) => {
      acc[row.id] = columns.reduce((fieldAcc, column) => {
        fieldAcc[column.field] = { mode: newMode }
        return fieldAcc
      }, {} as any)

      return acc
    }, {} as GridCellModesModel)

    setCellModesModel(updatedModel)
  }

  const handleCancel = () => {
    const updatedModel = Object.keys(cellModesModel).reduce((acc, rowId) => {
      const fields = cellModesModel[rowId]

      acc[rowId] = Object.keys(fields).reduce((fieldAcc, field) => {
        fieldAcc[field] = { mode: GridCellModes.View, ignoreModifications: true }

        return fieldAcc
      }, {} as any)

      return acc
    }, {} as GridCellModesModel)

    setCellModesModel(updatedModel)
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault()
  }

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        p: 1,
      }}
    >
      <Button
        onClick={handleSaveOrEdit}
        onMouseDown={handleMouseDown}
        variant="contained"
      >
        {cellMode === 'edit' ? 'Save' : 'Edit'}
      </Button>
      <Button
        color="error"
        onClick={handleCancel}
        onMouseDown={handleMouseDown}
        disabled={cellMode === 'view'}
        variant="outlined"
        sx={{ ml: 1 }}
      >
        Cancel
      </Button>
    </Box>
  )
}

export default function EditableDataGrid(
  { columns, columnVisibilityModel, handleSaveCallback, primaryField, rows, slots }:
    {
      columns: GridColDef[],
      columnVisibilityModel: GridColumnVisibilityModel,
      handleSaveCallback: Function,
      primaryField: string,
      rows: Array<any>,
      slots: Partial<GridSlots>
    }
) {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})
  const [editedRows, setEditedRows] = useState<Array<typeof rows>>(rows)

  const apiRef = useGridApiRef()

  const cellMode = React.useMemo(() => {
    return Object.values(cellModesModel).some((row) =>
      Object.values(row).some((cell) => cell.mode === GridCellModes.Edit)
    ) ? 'edit' : 'view'
  }, [cellModesModel])

  const handleCellEditStop = React.useCallback<GridEventListener<'cellEditStop'>>(
    (params, event) => {
      event.defaultMuiPrevented = true
    },
    []
  )

  useEffect(() => {
    if (editedRows.length > 0 && cellMode === "view") {
      const handler = setTimeout(() => {
        handleSaveCallback(editedRows)
      }, 1)

      return () => {
        clearTimeout(handler)
      }
    }
  }, [editedRows])

  useEffect(() => {
    if (apiRef.current && rows.length > 0) {
      const newRowValues = apiRef.current.getRowModels().values()

      // @ts-ignore
      setEditedRows(newRowValues.toArray())

      const firstEmptyRow = rows.find(row => row[primaryField] === "")

      if (!firstEmptyRow)
        return

      const firstBlankEmailId = firstEmptyRow.id

      setTimeout(() => {
        apiRef.current.setCellFocus(firstBlankEmailId, primaryField)
      }, 1)
    }
  }, [apiRef, cellModesModel])

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        apiRef={apiRef}
        autoHeight
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        cellModesModel={cellModesModel}
        disableColumnMenu
        disableColumnResize
        disableRowSelectionOnClick
        hideFooter
        onCellEditStop={handleCellEditStop}
        onCellModesModelChange={(model) => setCellModesModel(model)}
        rows={rows}
        slots={{
          toolbar: EditToolbar as GridSlots['toolbar'],
          ...slots
        }}
        slotProps={{
          toolbar: {
            apiRef,
            cellMode,
            cellModesModel,
            setCellModesModel,
            columns,
            rows,
          },
        }}
      />
    </div>
  )
}
