import { addDoc, collection, doc } from "@firebase/firestore"
import { firebaseDatabase } from "../firebase/firebaseConfig"

import { isNotEmpty, ValidatableActionableNameableItem } from "../serviceImpl"

import { CreatableItem, Validatable } from "../service"
import { ConversationMutable, ConversationOverview } from "./service"

export class Conversation
  extends ValidatableActionableNameableItem
  implements ConversationMutable, CreatableItem
{
  question: string
  subject: string
  protected questionValidator: Validatable<string>
  protected subjectValidator: Validatable<string>

  constructor({ id, name, question, status, subject }: ConversationOverview) {
    super(id, name, status)

    this.question = question
    this.subject = subject

    this.questionValidator = isNotEmpty()
    this.subjectValidator = isNotEmpty()
  }

  isQuestionValid(): boolean {
    return this.questionValidator.validate(this.question)
  }

  isSubjectValid(): boolean {
    return this.subjectValidator.validate(this.subject)
  }

  isValid(): boolean {
    return this.isQuestionValid() && this.isSubjectValid()
  }

  async createConversation(uid: string, organizationID: string, projectID: string, scheduleID: string): Promise<any> {
    if (!this.isValid())
      return

    const organizationRef = doc(firebaseDatabase, "organization", organizationID)

    const projectsRef = doc(collection(organizationRef, "project"), projectID)

    const scheduleRef = doc(collection(projectsRef, "schedule"), scheduleID)

    const conversationRef = collection(scheduleRef, "conversation")

    return addDoc(conversationRef, {
      creator: uid,
      question: this.question,
      subject: this.subject,
      status: this.status
    }).then((conversation) => {
      return conversation.id
    })
  }

  async submit(uid: string, organizationID: string, projectID: string, scheduleID: string):
    Promise<{ id?: string, success: boolean, errors?: Record<string, boolean> }>
  {
    if (!this.isValid() || !projectID || !scheduleID)
      return {
        errors: {
          question: !this.isQuestionValid(),
          subject: !this.isSubjectValid()
        },
        success: false
      }

    try {
      const id = await this.createConversation(uid, organizationID, projectID, scheduleID)

      return { id, success: true }
    } catch (error: any) {
      this.handleSubmissionError(error, "conversation")
      return { success: false }
    }
  }

  // @ts-ignore
  override toJSON(): { [key: string]: { question: string, subject: string } } {
    const parentJson = super.toJSON()

    return {
      [this.id]: {
        ...parentJson[this.id],
        question: this.question,
        subject: this.subject
      }
    }
  }
}