import React, { Dispatch, SetStateAction, useState } from "react"

import { enqueueSnackbar } from "notistack"

import Grid from "@mui/material/Grid2"
import { Google, Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, Button, FilledInput, IconButton, InputAdornment, Typography } from "@mui/material"

import { signInWithEmailAndPassword } from "firebase/auth"
import { signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail, AuthProvider } from "@firebase/auth"
import { firebaseAuth } from "../firebase/firebaseConfig"

import { useLoginStyles } from "../../Pages/PageLogin"

export default function Login({ setIsSignUp }: { setIsSignUp: Dispatch<SetStateAction<boolean>>}) {
  const [email, setEmail] = useState<string | null>()
  const [error, setError] = useState<string | null>()
  const [password, setPassword] = useState<string | null>("")
  const [showPassword, setShowPassword] = useState<Boolean>(false)

  const classes = useLoginStyles()

  const googleAuth = () => {
    signInWithProvider(new GoogleAuthProvider())
  }
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleEmailInput = (event: { target: { value: string } }) => setEmail(event.target.value)
  const handlePasswordInput = (event: { target: { value: string } }) => setPassword(event.target.value)

  const handleForgotPassword = () => {
    if (!email)
      return enqueueSnackbar("Please enter your email address.", {variant: "error"})

    sendPasswordResetEmail(firebaseAuth, email).then(() => {
      enqueueSnackbar("Password reset link sent.", {key: "reset", variant: "success", preventDuplicate: true})
    })
      .catch((error) => {
        setError(error.code)

        if (error.code === "auth/invalid-email")
          enqueueSnackbar("Enter a valid email address.", {key: "valid", variant: "error", preventDuplicate: true})
        else if (error.code === "auth/user-not-found")
          enqueueSnackbar("No account found with that email address.",
            {key: "nonexistent", variant: "error", preventDuplicate: true})
      })
  }
  const handleKeyDown = (e: { key: string }) => e.key === "Enter" ? handleSignIn() : null
  const handleSignIn = () => {
    if (!email)
      return enqueueSnackbar("Please enter your email address.", {
        key: "emailMissing",
        variant: "error",
        preventDuplicate: true
      })
    else if (!password)
      return enqueueSnackbar("Please enter your password.", {
        key: "passwordMissing",
        variant: "error",
        preventDuplicate: true
      })

    signInWithEmailAndPassword(firebaseAuth, email, password)
      .catch((error) => {
        setError(error.code)

        if (error.code === "auth/user-not-found")
          enqueueSnackbar("No user found with that email and password.", {
            key: "nonexistent",
            variant: "error",
            preventDuplicate: true
          })
        else if (error.code === "auth/wrong-password")
          enqueueSnackbar("Incorrect Email or Password.", {key: "incorrect", variant: "error", preventDuplicate: true})
        else
          enqueueSnackbar("Something went wrong. Please try again later.", {
            key: "sww",
            variant: "error",
            preventDuplicate: true
          })
      })
  }

  const signInWithProvider = (provider: AuthProvider) => {
    signInWithPopup(firebaseAuth, provider).catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      const email = error.customData.email
      const credential = GoogleAuthProvider.credentialFromError(error)

      console.log(errorCode, errorMessage, email, credential)
    })
  }

  return (
    <Box className={classes.container}>
      <Grid className={classes.maxWidth} container spacing={2}>
        <Typography className={classes.heroContentTitle} variant="h1">
          Login
        </Typography>

        <Button
          className={classes.button}
          onClick={googleAuth}
          size="large"
          startIcon={<Google />}
          title="Continue with Google"
          variant="outlined"
        >
          Continue with Google
        </Button>
      </Grid>

      <hr className={`${classes.maxWidth} hr-text`} data-content="Or"/>

      <div className={classes.form}>
          <FilledInput
            error={error?.includes("email")}
            id="email"
            onChange={handleEmailInput}
            placeholder="Email"
          />

          <FilledInput
            id="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowPassword}
                  title={showPassword ? "Hide Password" : "Show Password"}
                >
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            }
            placeholder="Password"
            error={error?.includes("password")}
            onChange={handlePasswordInput}
            onKeyDown={handleKeyDown}
            type={showPassword ? "text" : "password"}
          />

          <Typography className={classes.forgot} onClick={handleForgotPassword}>
            Forgot Password?
          </Typography>

          <Button
            className={classes.button}
            color="primary"
            disabled={!email || !password}
            onClick={handleSignIn}
            size="large"
            title="Log In"
            variant="contained"
          >
            Log In
          </Button>

          <Typography className={classes.signUp} onClick={() => setIsSignUp(true)}>
            Don't Have an account? Sign Up
          </Typography>
        </div>
    </Box>
  )
}