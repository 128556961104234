import React, { useState } from "react"

import { CheckCircleRounded } from "@mui/icons-material"
import { Box, Button, CircularProgress, DialogActions, FilledInput, Paper, Typography } from "@mui/material"

import { useFirebaseUser } from "../../firebase"
import { firebaseDatabase } from "../../firebase/firebaseConfig"
import { doc, onSnapshot } from "@firebase/firestore"

import { enqueueSnackbar } from "notistack"
import { FileUploader } from "react-drag-drop-files"

import { ProjectMutable, ProjectOverview } from "../service"
import { Project } from "../serviceImpl"
import { handleInput } from "../../serviceImpl"

export default function AddProject ({ handleClose }: { handleClose: any}) {
  const [errors, setErrors] = useState<any>({
    file: false,
    name: false
  })
  const [fields, setFields] = useState<ProjectMutable>({
    file: undefined,
    name: ""
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { organization, user } = useFirebaseUser()

  const handleFileUploaderChange = (file: File) => {
    setFields({
      ...fields,
      file: file
    })
  }

  const handleSubmit = async () => {
    if (!organization || !user)
      return

    setIsLoading(true)

    const project = new Project(fields as ProjectOverview)

    const data = await project.submit(user.uid, organization.id)

    if (data?.success) {
      enqueueSnackbar(`Successfully created project ${project.name}`, { variant: "success" })
      handleClose()

      if (!data.id)
        return

      const organizationRef = doc(firebaseDatabase, "organization", organization.id)

      const projectRef = doc(organizationRef, "project", data.id)

      const userRef = doc(projectRef, "editor", user.uid)

      onSnapshot(userRef, () => {
        user.reload()
      })
    }
    else {
      if (data.errors)
        setErrors(data.errors)
    }

    setIsLoading(false)
  }

  return (
    <Box p={3}>
      <Box className="mb-4" display="flex" flexDirection="column" gap={2}>
        {
          isLoading ? (
            <CircularProgress className="m-auto" size={150} />
          ) : (
            <Box display="flex" flexDirection="column" gap={2}>
              <Box>
                <Typography variant="h5">
                  Name
                </Typography>

                <FilledInput
                  error={errors.name}
                  id="name"
                  onChange={(event) => handleInput(event, setFields)}
                  value={fields.name}
                />
              </Box>

              <Box>
                <Typography variant="h5">
                  Purchase Agreement
                </Typography>

                <FileUploader
                  classes="mw-100"
                  handleChange={handleFileUploaderChange}
                  name="purchaseAgreement"
                  types={["DOC", "DOCX"]}
                />

                {
                  fields.file && (
                    <Paper
                      elevation={3}
                      style={{
                        padding: '16px',
                        marginTop: '16px',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography variant="body1">
                        {fields.file.name}
                      </Typography>
                      <Typography variant="body2" color="success" display="flex" alignItems="center" fontSize="0.75rem">
                        <CheckCircleRounded />
                        Attached
                      </Typography>
                    </Paper>
                  )
                }
              </Box>
            </Box>
          )
        }
      </Box>

      <DialogActions>
        <Button color="error" disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={handleSubmit} variant="contained">
          Create Project
        </Button>
      </DialogActions>
    </Box>
  )
}