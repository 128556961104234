import { getAnalytics } from "@firebase/analytics"
import { connectAuthEmulator, getAuth } from "@firebase/auth"
import { initializeApp } from "@firebase/app"
import { connectFirestoreEmulator, getFirestore } from "@firebase/firestore"
import { connectFunctionsEmulator, getFunctions } from "@firebase/functions"
import { connectStorageEmulator, getStorage } from "@firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyAUPkLL2dd73UJoLRLTRpuUadoLU4_upg8",
    authDomain: "full-disclosure-legal.firebaseapp.com",
    databaseURL: "https://full-disclosure-legal-default-rtdb.firebaseio.com",
    projectId: "full-disclosure-legal",
    storageBucket: "full-disclosure-legal.appspot.com",
    messagingSenderId: "326429709280",
    appId: "1:326429709280:web:ca685f1311cc3b643a9185",
    measurementId: "G-ZRBE3WCH5L"
}

export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseAnalytics = getAnalytics(firebaseApp)
export const firebaseFunctions = getFunctions(firebaseApp)
export const firebaseDatabase = getFirestore(firebaseApp)
export const firebaseStorage = getStorage(firebaseApp)

if (false && process.env.NODE_ENV === "development") {
    connectAuthEmulator(firebaseAuth, "http://localhost:9099")
    connectFirestoreEmulator(firebaseDatabase, "127.0.0.1", 8080)
    connectFunctionsEmulator(firebaseFunctions, "localhost", 5001)
    connectStorageEmulator(firebaseStorage, "localhost", 9199)
}