import React, { useState } from "react"

import { ArrowForwardIosRounded } from "@mui/icons-material"
import { Box, Button, Dialog, Drawer, Typography } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid"

import Conversation from "./conversation"
import ProjectStatusChip from "../projectstatuschip"
import AddConversation from "./conversation/addconversation"

import { ConversationOverview, ConversationsComponent } from "./service"

export default function Conversations({ conversations, projectID, scheduleId }: ConversationsComponent) {
  const [conversationSelected, setConversationSelected] = useState<ConversationOverview | null>()
  const [isConversationAddOpen, setIsConversationAddOpen] = useState<boolean>(false)

  const handleConversationSelect = (conversation: ConversationOverview) => setConversationSelected(conversation)
  const handleConversationClear = () => setConversationSelected(null)
  const handleConversationAddOpen = () => setIsConversationAddOpen(true)
  const handleConversationAddClose = () => setIsConversationAddOpen(false)

  const columns: GridColDef<ConversationOverview>[] = [
    { field: "id" },
    {
      field: "subject",
      flex: .5,
      headerName: "Subject"
    },
    {
      field: "question",
      flex: 1,
      headerName: "Question"
    },
    {
      field: "status",
      flex: .5,
      headerName: "Status",
      renderCell: ({ value }) => <ProjectStatusChip status={value} />
    },
    {
      field: "actions",
      flex: .5,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<ArrowForwardIosRounded />}
          label={`View conversation ${row.subject}`}
          onClick={() => handleConversationSelect(row)}
        />
      ],
      headerName: "",
      renderHeader: () => (
        <Button onClick={handleConversationAddOpen} variant="contained">
          New Conversation
        </Button>
      ),
      type: "actions"
    }
  ]

  return (
    <Box>
      <DataGrid
        autoHeight
        columns={columns}
        disableColumnMenu
        disableColumnResize
        disableRowSelectionOnClick
        hideFooter
        columnVisibilityModel={{ id: false }}
        rows={Object.values(conversations)}
        slots={{
          noRowsOverlay: () => (
            <Typography
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center"
              }}
              variant="subtitle1"
            >
              No Conversations
            </Typography>
          )
        }}
      />

      {
        isConversationAddOpen && (
          <Dialog
            onClose={handleConversationAddClose}
            open={isConversationAddOpen}
            PaperProps={{
              sx: { width: "50%" },
            }}
          >
            <AddConversation
              handleAddCallback={handleConversationSelect}
              handleClose={handleConversationAddClose}
              projectID={projectID}
              scheduleID={scheduleId}
            />
          </Dialog>
        )
      }

      <Drawer
        anchor="right"
        onClose={handleConversationClear}
        open={Boolean(conversationSelected)}
        elevation={1}
        PaperProps={{
          sx: { width: "50%" },
        }}
      >
        {
          conversationSelected && (
            <Conversation
              handleCloseDrawer={handleConversationClear}
              id={conversationSelected.id}
              projectID={projectID}
              scheduleID={scheduleId}
              subject={conversationSelected.subject}
              question={conversationSelected.question}
            />
          )
        }
      </Drawer>
    </Box>
  )
}