import React from "react"

import { CloseRounded } from "@mui/icons-material"
import { styled } from "@mui/styles"

import { createTheme, CssBaseline, IconButton, ThemeProvider } from "@mui/material"

import { closeSnackbar, MaterialDesignContent, SnackbarProvider } from "notistack"

import { FirebaseUserProvider } from "./components/firebase"
import PageHome from "./Pages/PageHome"
import { getTheme } from "./Theme"

import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"

const StyledSnackBars = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#2AE89F",
    color: "#000"
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#EC7588",
  },
}))

function App() {
  return (
    <SnackbarProvider
      action={snackbarKey => (
        <IconButton className="p-1" onClick={() => closeSnackbar(snackbarKey)} >
          <CloseRounded />
        </IconButton>
      )}
      Components={{
        success: StyledSnackBars,
        error: StyledSnackBars,
      }}
      maxSnack={3}
    >
      <FirebaseUserProvider>
        <ThemeProvider theme={createTheme(getTheme("dark"))}>
          <CssBaseline />

          <PageHome />
        </ThemeProvider>
      </FirebaseUserProvider>
    </SnackbarProvider>
  )
}

export default App
