import React, { useState } from "react"

import { Avatar, Box, Divider, ListItemIcon, Menu, MenuItem, Skeleton, Typography } from "@mui/material"
import {
  DeleteForeverRounded,
  ExitToAppRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded
} from "@mui/icons-material"

import { useFirebaseUser } from "../firebase"

export default function UserDropdown() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const { handleUserDelete, handleUserSignOut, isOrganizationLoading, organization, role, user } = useFirebaseUser()

  const noOrganization = !organization && !isOrganizationLoading

  return (
    <Box>
      <Box style={{ alignItems: "center", cursor: "pointer", display: "flex", justifyContent: "flex-end" }}>
        <Avatar
          alt={user?.displayName || ""}
          src={user?.photoURL || ""}
          sx={{ width: { xs: 40, md: 56 }, height: { xs: 40, md: 56 }, marginRight: 1, border: "2px solid #9e9ea7" }}
        />
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <Typography variant="body1">
            {user?.displayName || user?.email}
          </Typography>
          {
            user && (
              <Typography variant="subtitle2">
                {role}
                {anchorEl ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
              </Typography>
            )
          }
        </Box>
      </Box>

    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      id="user-menu"
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      style={{ marginTop: 15 }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <MenuItem>
        <Box display="flex" flexDirection="column">
          <Typography fontSize=".75rem" variant="subtitle1">
            Organization
          </Typography>
          {
            organization ? (
              <Typography fontSize="1.25rem">
                {
                  organization.status === 2
                    ? "Get Started"
                    : organization?.name
                }
              </Typography>
            ) : (
              noOrganization ? (
                "Full Disclosure"
              ) : (
                <Skeleton height={30} width={120} />
              )
            )
          }
        </Box>
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => handleUserDelete()}>
        <ListItemIcon>
          <DeleteForeverRounded />
        </ListItemIcon>
        Delete User
      </MenuItem>

      <MenuItem onClick={() => handleUserSignOut()}>
        <ListItemIcon>
          <ExitToAppRounded />
        </ListItemIcon>
        Sign Out
      </MenuItem>
    </Menu>
  </Box>
  )
}