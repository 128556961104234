import { colors } from "@mui/material"
import { createTheme } from "@mui/material/styles"

const colorDefault = "#1E212B"
const colorPaper = "#252935"
const colorPaperDark = "#15181D"
const colorRed = "#EC7588"
const colorSecondary = "#8892AF"
const colorGreen = "#2AE89F"

export const getTheme = (theme) => {
  if (theme === "light")
    return light

  return dark
}

export const dark = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.MuiPaper-rounded": {
            borderRadius: 20,
            '::before': {
              display: "none"
            }
          },
          "&.Mui-expanded": {
            margin: 0
          }
        },
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          marginRight: 20,
          minWidth: "100px !important"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          minHeight: "44px",
          "&.MuiButton-containedGreen": {
            color: colorDefault
          },
          "&.MuiButton-outlined": {
            border: "1px solid #404357",
            "&.MuiButton-outlinedPrimaryLight": {
              border: "1px solid #BEA4FF"
            },
            ".MuiButton-startIcon": {
              margin: 0
            }
          },
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        label: {
          ':empty': {
            paddingLeft: 0
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          ".MuiTypography-root": {
            alignItems: "center",
            display: "flex",
            height: "100%"
          },
          ":focus": {
            outline: "none !important"
          },
          alignItems: "center",
          display: "flex"
        },
        columnHeader: {
          backgroundColor: colorPaperDark,
          ":focus": {
            outline: "none !important"
          }
        },
        editInputCell: {
          height: "100%"
        },
        iconButtonContainer: {
          marginLeft: 10
        },
        root: {
          backgroundColor: colorPaper,
          border: `1px solid ${colorPaperDark}`,
          borderRadius: 20,
          "--rowBorderColor": "",
          ".MuiInputBase-root, .MuiIconButton-root": {
            backgroundColor: colorDefault
          }
        },
        rowBorderColor: "red",
        virtualScrollerContent: {
          backgroundColor: colorPaper
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiDialogTitle-root": {
            fontWeight: 600,
            textAlign: "left"
          }
        },
        paper: {
          backgroundColor: colorPaperDark,
          minHeight: 200,
          minWidth: 400,
          "--Paper-overlay": "0 !important",
          "& .MuiPaper-root": {
            backgroundColor: colorPaper,
            "--Paper-overlay": "0 !important"
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: `${colorPaper} !important`,
          border: "1px solid #394049",
          borderRadius: 12,
          color: "#FFF",
          minHeight: 55,
          padding: "8px 8px 8px 16px !important",
          textAlign: "left",
          width: "100%",
          "&::before, &::after, &:hover:not(.Mui-disabled, .Mui-error):before, &.Mui-focused:after": {
            borderBottom: "none",
            borderBottomStyle: "hidden !important"
          },
          "& .MuiFilledInput-input": {
            padding: 0
          },
          "&.Mui-error": {
            border: `1px solid ${colorRed}`
          },
          "& .MuiAutocomplete-endAdornment, .MuiInputAdornment-positionEnd": {
            top: 0,
            transform: "none",
            "& .MuiIconButton-root": {
              backgroundColor: "transparent",
              border: "none"
            }
          }
        },
        sizeSmall: {
          height: 41
        }
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
       root: {
         color: "#8E94A7",
         fontWeight: 400,
         fontSize: 10,
         lineHeight: "16px",
         margin: 5
       }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: colorPaper,
          border: "1px solid #394049",
          borderRadius: 12,
          "& svg": {
            color: colorSecondary
          },
          "&.Mui-disabled": {
            border: "none",
            opacity: .3
          }
        },
        sizeSmall: {
          height: 41,
          width: 41,
        },
        sizeMedium: {
          height: 52,
          width: 52,
        },
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "& .MuiButton-root": {
            borderRadius: 6,
            minHeight: 30
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 6
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        padding: {
          paddingRight: 60
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          gap: 4,
          padding: "10px 16px"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          ".MuiPaper-root, .MuiFilledInput-root, .MuiDataGrid-root": {
            backgroundColor: colorPaperDark
          },
        }
      }
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          marginTop: "5px !important",
          position: "fixed",
          zIndex: "1500 !important"
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: 0
        },
        select: {
          alignItems: "center",
          display: "flex",
          height: "100%",
          padding: 0,
          ":focus": {
            backgroundColor: "inherit"
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: colorSecondary,
          height: 44,
          minWidth: "0 !important",
          minHeight: 0,
          "&.Mui-selected": {
            color: "var(--accent)",
            zIndex: 1
          }
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: `${colorDefault} !important`,
          borderRadius: 8,
          color: "var(--accent)",
          height: 44
        },
        root: {
          backgroundColor: colorPaper,
          borderRadius: 12,
          height: 60,
          padding: 8
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%"
        }
      }
    }
  },
  palette: {
    mode: "dark",
    background: {
      default: colorDefault,
      paper: colorPaper,
      paperDark: colorPaperDark
    },
    default: {
      main: colorDefault
    },
    green: {
      main: colorGreen,
    },
    primary: {
      main: "#905EFF"
    },
    primaryLight: {
      main: "#BEA4FF",
    },
    red: {
      main: colorRed
    },
    secondary: {
      main: colorSecondary
    },
    success: {
      main: colorGreen,
    },
    error: {
      main: colorRed,
    }
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    button: {
      textTransform: "none"
    },
    fontFamily: [
      "Roboto",
      "sans-serif"
    ].join(","),
    h1: {
      fontSize: "3.5rem",
      fontWeight: 700,
      lineHeight: "72px",
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: "72px",
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 500
    },
    subtitle1: {
      color: "#8E94A7 !important",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "28px",
      wordWrap: "break-word"
    },
    subtitle2: {
      color: "#8E94A7",
      fontSize: 14,
      fontWeight: 500,
      textAlign: "left",
      wordWrap: "break-word"
    }
  }
})

export const light = createTheme({
  palette: {
    mode: "light",
    background: {
      gradient: "linear-gradient(90deg, #ededed,  #ffffff,  #ededed)",
      gradientInverse: "linear-gradient(90deg, #ffffff,  #ededed,  #ffffff)",
    },
    primary: {
      main: colors.deepPurple[500]
    },
    secondary: {
      main: colors.grey[900]
    }
  },
  typography: {
    fontFamily: [
      "Roboto",
      "sans-serif"
    ].join(",")
  }
})
