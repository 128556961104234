import React from "react"

import {
  DoneRounded,
  HourglassBottomRounded,
  InfoRounded,
  PriorityHighRounded,
  VerifiedRounded
} from "@mui/icons-material"
import { Chip, Tooltip } from "@mui/material"

import { ProjectStatusChipDetails } from "../projects/service"

export default function ProjectStatusChip({ isOnlyIcon, status }: { isOnlyIcon?: boolean, status: number}) {
  const projectStatusChip: { [key: string]: ProjectStatusChipDetails } = {
    0: {
      color: "error",
      icon: <PriorityHighRounded />,
      label: "Outstanding Items"
    },
    1: {
      color: "info",
      icon: <VerifiedRounded />,
      label: "Ready For Collaborators"
    },
    2: {
      color: "warning",
      icon: <HourglassBottomRounded />,
      label: "Awaiting Collaborators"
    },
    3: {
      color: "info",
      icon: <InfoRounded />,
      label: "Ready For Review"
    },
    4: {
      color: "success",
      icon: <DoneRounded />,
      label: "Complete"
    }
  }

  const { color, icon, label } = projectStatusChip[status]

  return <Tooltip title={isOnlyIcon ? label : null}>
    <Chip
      color={color}
      icon={icon}
      label={isOnlyIcon ? null : label}
    />
  </Tooltip>
}