import React, { useState } from "react"

import { Box, Button, DialogActions, FilledInput, Typography } from "@mui/material"

import { useFirebaseUser } from "../../../firebase"

import { ConversationAddComponent, ConversationMutable, ConversationOverview } from "../../service"

import { handleInput } from "../../../serviceImpl"
import { Conversation } from "../../serviceImpl"

export default function AddConversation (
  { handleClose, handleAddCallback, projectID, scheduleID }: ConversationAddComponent
) {
  const [errors, setErrors] = useState<any>({
    question: false,
    subject: false
  })
  const [fields, setFields] = useState<ConversationMutable>({
    question: "",
    subject: ""
  })

  const { organization, user } = useFirebaseUser()

  const handleChange = (event: { target: { id: any; value: any } }) => handleInput(event, setFields)
  const handleSubmit = async () => {
    if (!organization || !user || !projectID || !scheduleID)
      return

    const conversation = new Conversation(fields as ConversationOverview)

    const data = await conversation.submit(user.uid, organization.id, projectID, scheduleID)

    if (data?.success) {
      handleClose()
      handleAddCallback(conversation)
    }
    else {
      if (data.errors)
        setErrors(data.errors)
    }
  }

  return (
    <Box p={3}>
      <Box className="mb-4" display="flex" flexDirection="column" gap={2}>
        <Box>
          <Typography variant="h5">
            Subject
          </Typography>

          <FilledInput
            error={errors?.subject}
            id="subject"
            onChange={handleChange}
            required
            value={fields.subject}
          />
        </Box>

        <Box>
          <Typography variant="h5">
            Question
          </Typography>

          <FilledInput
            error={errors?.question}
            id="question"
            onChange={handleChange}
            required
            value={fields.question}
          />
        </Box>
      </Box>

      <DialogActions>
        <Button color="error" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Box>
  )
}