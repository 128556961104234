import React from "react"

import { Box, Typography } from "@mui/material"
import { CheckCircle } from "@mui/icons-material"

import { PasswordValidationListComponent } from "./types"

export default function PasswordValidationList ({ hidden = false, passwordValid }: PasswordValidationListComponent) {
  return (
    <Box className="text-left" hidden={hidden} style={{ display: "flex", flexDirection: "column" }}>
      <Box style={{ alignItems: "center", display: "flex" }}>
        <CheckCircle color={passwordValid.uppercase ? "success" : "secondary"} />
        <Typography color="secondary" component="p">One uppercase letter</Typography>
      </Box>
      <Box style={{ alignItems: "center", display: "flex" }}>
        <CheckCircle color={passwordValid.lowercase ? "success" : "secondary"} />
        <Typography color="secondary" component="p">One lowercase letter</Typography>
      </Box>
      <Box style={{ alignItems: "center", display: "flex" }}>
        <CheckCircle color={passwordValid.number ? "success" : "secondary"} />
        <Typography color="secondary" component="p">One number</Typography>
      </Box>
      <Box style={{ alignItems: "center", display: "flex" }}>
        <CheckCircle color={passwordValid.special ? "success" : "secondary"} />
        <Typography color="secondary" component="p">One special character</Typography>
      </Box>
      <Box style={{ alignItems: "center", display: "flex" }}>
        <CheckCircle color={passwordValid.length ? "success" : "secondary"} />
        <Typography color="secondary" component="p">8 characters long</Typography>
      </Box>
      <Box style={{ alignItems: "center", display: "flex" }}>
        <CheckCircle color={passwordValid.match ? "success" : "secondary"} />
        <Typography color="secondary" component="p">Passwords Match</Typography>
      </Box>
    </Box>
  )
}