import { addDoc, collection, doc } from "@firebase/firestore"
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage"
import { firebaseDatabase, firebaseStorage } from "../firebase/firebaseConfig"

import { ProjectMutable, ProjectOverview } from "./service"
import { ActionableItem, CreatableItem } from "../service"
import { ScheduleOverviewMutable } from "../schedule/service"

import { ValidatableActionableNameableItem, ValidatableImpl } from "../serviceImpl"

export class Project
  extends ValidatableActionableNameableItem
  implements ActionableItem, ProjectMutable, CreatableItem
{
  file?: File
  schedules: Array<ScheduleOverviewMutable>
  protected fileValidator: ValidatableImpl<File>

  constructor({ id, file, name, schedules, status }: ProjectOverview) {
    super(id, name, status)
    this.file = file
    this.fileValidator = isFileNotEmpty()
    this.schedules = schedules
  }

  isFileValid(): boolean {
    return this.fileValidator.isValid(this.file as File)
  }

  override isValid(): boolean {
    return super.isValid() && this.isFileValid()
  }

  async uploadFile(organizationID: string, projectID: string): Promise<string> {
    if (!this.file) throw new Error("File not provided")

    const storageRef = ref(firebaseStorage,
      `/organization/${organizationID}/project/${projectID}/original/${this.file.name}`)
    const snapshot = await uploadBytes(storageRef, this.file)
    return getDownloadURL(snapshot.ref)
  }

  async createProject( uid: string, organizationID: string): Promise<any> {
    if (!this.file)
      return

    const organizationRef = doc(firebaseDatabase, "organization", organizationID)
    const projectsRef = collection(organizationRef, "project")

    return addDoc(projectsRef, {
      creator: uid,
      name: this.name,
      parse: true,
      purchaseAgreementDocName: this.file.name,
      status: this.status
    }).then((project) => {
      return project.id
    })
  }

  async submit(
    uid: string, organizationID: string): Promise<{ id?: string, success: boolean, errors?: Record<string, boolean> }>
  {
    if (!this.isValid()) {
      return {
        errors: {
          name: !this.isNameValid(),
          file: !this.isFileValid(),
        },
        success: false
      }
    }

    try {
      const id = await this.createProject(uid, organizationID)
      await this.uploadFile(organizationID, id)

      return { id, success: true }
    } catch (error: any) {
      this.handleSubmissionError(error, "organization")
      return { success: false }
    }
  }

  // @ts-ignore
  override toJSON(): { [key: string]: { file?: File, schedules: Array<ScheduleOverviewMutable> } } {
    const parentJson = super.toJSON()

    return {
      [this.id]: {
        ...parentJson[this.id],
        file: this.file,
        schedules: this.schedules || [] // Ensure schedules is an array
      }
    }
  }
}

export const isFileNotEmpty = () => {
  return new ValidatableImpl<File>((file: File) => file instanceof File)
}