import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useState } from "react"

import Login from "../components/auth/login"
import SignUp from "../components/auth/signup"

// @ts-ignore
export const useLoginStyles = makeStyles((theme: Theme) => ({
  button: {
    color: "#FFF !important",
    display: "flex",
    gap: 8,
    height: 60,
    width: "100%"
  },
  container: {
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column !important",
    height: "100vh",
    minHeight: "55vh",
    justifyContent: "center",
    width: "100%",
    padding: "7rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: "3rem",
      "& h1": {
        fontSize: "2.75rem"
      }
    }
  },
  forgot: {
    color: "#8892AF",
    cursor: "pointer",
    display: "block",
    fontSize: "14px !important",
    marginTop: 5,
    textAlign: "right",
    textDecoration: "underline"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    margin: "0 auto",
    maxWidth: 600,
    width: "100%"
  },
  heroContentCTA: {
    lineHeight: "24px",
    marginRight: "auto !important",
    padding: "28px 14px",
    width: 230
  },
  heroContentSubtitle: {
    marginBottom: "40px !important"
  },
  heroContentTitle: {
    fontSize: "3.5rem",
    marginBottom: "24px !important",
    width: "100%"
  },
  maxWidth: {
    maxWidth: 600,
    width: "100%"
  },
  signUp: {
    alignItems: "center",
    color: "#8892AF",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
  }
}))

export default function PageLogin() {
  const [isSignUp, setIsSignUp] = useState<boolean>(false)

  if (isSignUp)
    return <SignUp setIsSignUp={setIsSignUp} />

  return <Login setIsSignUp={setIsSignUp} />
}