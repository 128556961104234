import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { Box, Button, FilledInput, Typography } from "@mui/material"
import Grid from "@mui/material/Grid2"
import { Google } from "@mui/icons-material"

import { signInWithPopup, GoogleAuthProvider, sendEmailVerification, AuthProvider } from "@firebase/auth"
import { createUserWithEmailAndPassword } from "firebase/auth"
import { useFirebaseUser } from "../firebase"
import { firebaseAuth } from "../firebase/firebaseConfig"

import { enqueueSnackbar } from "notistack"

import { useLoginStyles } from "../../Pages/PageLogin"
import PasswordValidationList from "./passwordvalidationlist"
import { PasswordValid } from "./types"

export default function SignUp({ setIsSignUp }: { setIsSignUp: Dispatch<SetStateAction<boolean>>}) {
  const [email, setEmail] = useState<string | null>()
  const [error, setError] = useState<string | null>()
  const [isVerifyingEmail, setIsVerifyingEmail] = useState<Boolean | null>(false)
  const [password, setPassword] = useState<string | null>("")
  const [passwordConfirm, setPasswordConfirm] = useState<string | null>("")
  const [passwordValid, setPasswordValid] = useState<PasswordValid>({
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    length: false,
    match: false
  })

  const classes = useLoginStyles()
  const { user } = useFirebaseUser()

  const googleAuth = () => {
    signUpWithProvider(new GoogleAuthProvider())
  }
  const handleEmailInput = (event: { target: { value: string } }) => setEmail(event.target.value)
  const handlePasswordInput = (event: { target: { value: string } }) => setPassword(event.target.value)
  const handlePasswordConfirmInput = (event: { target: { value: string } }) => setPasswordConfirm(event.target.value)
  const handleKeyDown = (e: { key: string }) => e.key === "Enter" ? handleSignUp() : null
  const handleSendEmail = (newUser = user) => {
    if (!newUser || newUser?.emailVerified)
      return

    sendEmailVerification(newUser)
      .then(() => {
        enqueueSnackbar("Verification email sent. Please verify your email address.", {variant: "success"})

        // @ts-ignore
        const queryEmailVerified = setInterval(() => {
          if (newUser?.emailVerified) {
            setIsVerifyingEmail(false)

            return clearInterval(queryEmailVerified)
          }
        }, 1000)
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests")
          enqueueSnackbar("Hmm, seems like you've requested too many emails. Try again later.", {
            key: "too-many",
            variant: "error",
            preventDuplicate: true
          })
        else if (error.code === "auth/user-token-expired")
          return
      })
  }
  const handleSignUp = () => {
    if (email && password)
      createUserWithEmailAndPassword(firebaseAuth, email, password)
        .then(({user: newUser}) => {
          setIsVerifyingEmail(true)

          if (!newUser.emailVerified) {
            handleSendEmail(newUser)
          }
        })
        .catch((error) => {
          console.log(error)
          setError(error.code)

          if (error.code === "auth/email-already-in-use")
            enqueueSnackbar("This email address is already registered.", {
              key: "duplicate",
              variant: "error",
              preventDuplicate: true
            })
          else
            enqueueSnackbar("Something went wrong. Please try again later.", {
              key: "sww",
              variant: "error",
              preventDuplicate: true
            })
        })
  }

  const signUpWithProvider = (provider: AuthProvider) => {
    signInWithPopup(firebaseAuth, provider)
      .then(() => {
        setIsVerifyingEmail(true)
      }).catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      const email = error.customData.email
      const credential = GoogleAuthProvider.credentialFromError(error)

      console.log(errorCode, errorMessage, email, credential)
    })
  }

  useEffect(() => {
    if (user?.emailVerified)
      setIsVerifyingEmail(false)
  }, [user])

  useEffect(() => {
    if (typeof password === "string") {
      setPasswordValid({
        uppercase: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        match: password === passwordConfirm,
        number: /[0-9]/.test(password),
        special: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password),
        length: password.length > 7
      })
    }
  }, [password, passwordConfirm])

  return (
    <Box className={classes.container}>
      <Grid className={classes.maxWidth} container spacing={2}>
        <Typography className={classes.heroContentTitle} variant="h1">
          Sign Up
        </Typography>

        {
          isVerifyingEmail ? (
            <Box style={{ padding: typeof window !== "undefined" && window.innerWidth < 768 ? 0 : 35, margin: "2rem 0.75rem", maxWidth: 400 }}>
              <Box style={{ marginTop: "15%" }}>
                <Typography variant="h5">
                  Welcome {user?.displayName || user?.email || email}!
                  <br /><br />
                  We just need you to verify your email. Please check your inbox!
                </Typography>

                <Typography
                  color="primary"
                  onClick={() => handleSendEmail(user)}
                  style={{ cursor: "pointer", display: "block", marginTop: 15, textDecoration: "underline" }}
                  variant="subtitle2"
                >
                  No Email? Click here to recieve a new one.
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <Button
                className={classes.button}
                onClick={googleAuth}
                size="large"
                startIcon={<Google />}
                title="Continue with Google"
                variant="outlined"
              >
                Continue with Google
              </Button>

              <hr className="hr-text" data-content="Or"/>

              <div className={classes.form}>
                <FilledInput
                  error={error?.includes("email")}
                  id="email"
                  onChange={handleEmailInput}
                  placeholder="Email"
                />

                <FilledInput
                  id="password"
                  placeholder="Password"
                  error={error?.includes("password")}
                  onChange={handlePasswordInput}
                  type="password"
                />

                <FilledInput
                  id="password-confirm"
                  placeholder="Confirm Password"
                  error={error?.includes("password")}
                  onChange={handlePasswordConfirmInput}
                  onKeyDown={handleKeyDown}
                  type="password"
                />


                {
                  password && (
                    <PasswordValidationList passwordValid={passwordValid} />
                  )
                }

                <Button
                  className={classes.button}
                  color="primary"
                  disabled={!email || !Object.values(passwordValid).reduce((a, b) => a && b)}
                  onClick={handleSignUp}
                  size="large"
                  title="Sign Up"
                  variant="contained"
                >
                  Sign Up
                </Button>

                <Typography className={classes.signUp} onClick={() => setIsSignUp(false)}>
                  Have an account? Login
                </Typography>
              </div>
            </>
          )
        }
      </Grid>
    </Box>
  )
}