import { enqueueSnackbar } from "notistack"
import { SetStateAction } from "react"

import { v4 as uuid } from "uuid"

import { ActionableNameableItem, License, Validatable, ValidatableNameable } from "./service"

export class ValidatableImpl<T> implements Validatable<T> {
  validate: (value: T) => boolean

  constructor(validate: (value: T) => boolean) {
    this.validate = validate
  }

  isValid(value: T): boolean {
    return this.validate(value)
  }
}

export class ValidatableNameableImpl implements ValidatableNameable {
  name: string
  nameValidator: Validatable<string>

  constructor(name: string) {
    this.name = name
    this.nameValidator = isNotEmpty()
  }

  isNameValid(): boolean {
    return this.nameValidator.validate(this.name)
  }
}

export class ValidatableItem {
  id: string

  constructor(id: string) {
    this.id = id
  }

  toJSON(): { [key: string]: {} } {
    return {
      [this.id]: {}
    }
  }
}

class OverviewItem {
  id: string
  name: string

  constructor(name: string, id?: string) {
    this.id = id || uuid()
    this.name = name
  }

  toJSON(): { id: string, name: string } {
    return {
      id: this.id,
      name: this.name
    }
  }
}


export class OverviewActionableItem extends OverviewItem {
  status: number

  constructor(name: string, status: number = 0, id?: string) {
    super(name, id)

    this.status = status
  }

  override toJSON(): { id: string, name: string, status: number } {
    return {
      ...super.toJSON(),
      status: this.status
    }
  }
}

export class ValidatableActionableItem extends ValidatableItem {
  status: number

  constructor(id: string, status: number = 0) {
    super(id)

    this.status = status
  }

  toJSON(): { [key: string]: { status: number } } {
    return {
      [this.id]: {
        status: this.status,
      }
    }
  }
}

export class ValidatableActionableNameableItem extends ValidatableActionableItem implements ActionableNameableItem {
  name: string
  nameValidator: ValidatableImpl<string>

  constructor(id: string, name: string, status: number) {
    super(id, status)
    this.name = name
    this.nameValidator = isNotEmpty()
  }

  handleSubmissionError(error: any, scope: string) {
    if (error.code === "storage/unauthorized") {
      enqueueSnackbar("You're not authorized to upload this Purchase Agreement.", {
        key: "pa-upload",
        variant: "error",
        preventDuplicate: true,
      })
    } else if (error.code === "permission-denied") {
      enqueueSnackbar(`You are not authorized to create a ${
        scope === "organization" ? "project for this organization" : "conversation for this schedule."}.`,
        {
          key: "project-create",
          variant: "error",
          preventDuplicate: true,
        })
    } else {
      console.log(error)
      enqueueSnackbar("Something Went Wrong.", { key: "image-upload", variant: "error", preventDuplicate: true })
    }
  }

  isNameValid(): boolean {
    return this.nameValidator.validate(this.name)
  }

  isValid(): boolean {
    return this.isNameValid()
  }

  toJSON(): { [key: string]: { name: string; status: number } } {
    return {
      [this.id]: {
        name: this.name,
        status: this.status
      }
    }
  }
}

export class Organization extends OverviewActionableItem {
  license: License
  logoURL: string

  constructor(name: string, status: number, license: License, logoURL: string, id?: string) {
    super(name, status, id)

    this.license = license
    this.logoURL = logoURL
  }

  override toJSON(): { id: string, license: License, logoURL: string, name: string, status: number } {
    return {
      ...super.toJSON(),
      license: this.license,
      logoURL: this.logoURL
    }
  }
}


export const handleInput = (event: { target: { id: string, value: any } }, setField: SetStateAction<any>) => {
  setField((prevState: any) => ({
    ...prevState,
    [event.target.id]: event.target.value
  }))
}

export const isNotEmpty = () => {
  return new ValidatableImpl<string>((value: string) => value.length > 0)
}