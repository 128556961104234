import React from "react"

import { Avatar, Box, Typography } from "@mui/material"

import { useFirebaseUser } from "../../firebase"

import ConversationMessageImages from "./ConversationMessageImages"

import { ConversationMessagesComponent } from "../service"

export default function ConversationMessages ({ storageURL, messages }: ConversationMessagesComponent) {
  const { user } = useFirebaseUser()

  if (!user)
    return null

  return (
    <Box marginTop={4}>
      {
        messages.sort((a, b) => a.created.seconds - b.created.seconds).map((message) => {
          const { id, content, created, sender } = message

          return (
            <Box alignItems={sender.email === user.email ? "flex-end" : "flex-start"} display="flex" flexDirection="column" gap={1}>
              <Typography variant="subtitle1">
                {
                  created.toDate().toDateString()} {created.toDate().toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit"
                  })
                }
              </Typography>

              <Box alignItems="center" display="flex" gap={1}>
                <Avatar alt={sender.email} src={sender.photoURL} title={sender.email}/>
                <Typography className="ml-1" variant="subtitle1">
                  {sender.email}
                </Typography>
              </Box>
              <Box style={{whiteSpace: "pre-line"}}>
                {content}
              </Box>

              <ConversationMessageImages id={id} storageURL={storageURL}/>

              <hr className="mt-0 w-100" />
            </Box>
          )
        })
      }
    </Box>
  )
}