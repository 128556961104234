import { enqueueSnackbar } from "notistack"
import React, { useEffect, useState } from "react"

import { Box, Button, CircularProgress, Container, Paper, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"

import { collection, doc, onSnapshot } from "@firebase/firestore"
import { httpsCallable } from "@firebase/functions"
import { useFirebaseUser } from "../firebase"
import { firebaseDatabase, firebaseFunctions } from "../firebase/firebaseConfig"

import EditableDataGrid from "../editabledatagrid"

import { Partner } from "./service"

export default function Administrator() {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [licensesUsed, setLicensesUsed] = useState<number>(0)
  const [rows, setRows] = useState<Array<Partner>>([])

  const { organization } = useFirebaseUser()

  const columns: GridColDef<Partner>[] = [
    {
      editable: true,
      field: "email",
      flex: 1,
      headerName: "Email"
    },
    {
      field: "id",
      flex: 1,
      headerName: "Email"
    },
    {
      editable: true,
      field: "name",
      flex: 1,
      headerName: "Name"
    }
  ]

  const handleSaveLicenses = (savedRows: Array<Partner>) => {
    if (!organization || JSON.stringify(rows) === JSON.stringify(savedRows))
      return

    setIsLoading(true)

    const assignSeat = httpsCallable(firebaseFunctions, "assignSeat")

    const handler = setTimeout(() => {
      assignSeat({
        newPartners: savedRows,
        organizationID: organization.id,
        organizationLogoURL: organization.logoURL,
        organizationName: organization.name
      }).catch((error) => {
        console.log(error)

        enqueueSnackbar("Something went wrong. Please try again later.", {
          key: "license-updated",
          variant: "error",
          preventDuplicate: true
        })
      }).then(() => {
        enqueueSnackbar("Successfully updated licenses!", {
          key: "license-updated",
          variant: "success",
          preventDuplicate: true
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
    }, 100)

    return () => {
      clearTimeout(handler)
    }
  }

  useEffect(() => {
    if (!organization)
      return

    const organizationRef = doc(firebaseDatabase, "organization", organization.id)

    const membersRef = collection(organizationRef, "editor")

    setRows([])

    onSnapshot(membersRef, (partners) => {
      setIsLoading(true)

      let newRows: Array<Partner> = []

      partners.forEach(partner => {
        const { email, name } = partner.data()

        newRows.push({
          email,
          id: partner.id,
          name
        })
      })

      const availableSlots = organization.license.available - newRows.length

      for (let i = 0; i < availableSlots; i++) {
        newRows.push({ email: "", id: newRows.length.toString(), name: "" });
      }

      setLicensesUsed(newRows.filter(row => row.email).length)
      setRows(newRows)

      setTimeout(() => {
        setIsLoading(false)
      }, 100)
    })
  }, [organization])

  return (
    <Container component={Paper} maxWidth="sm">
      <Box className="pt-4" display="flex" flexDirection="column" gap={2}>
        <Typography fontWeight={600} variant="h4">
          Licenses
        </Typography>

        <Typography variant="subtitle1">
          Assigned {licensesUsed} of {organization?.license.available || 0}
        </Typography>

        <EditableDataGrid
          columnVisibilityModel={{ id: false }}
          columns={columns}
          handleSaveCallback={handleSaveLicenses}
          primaryField="email"
          rows={rows}
          slots={{
            noRowsOverlay: () => (
              <Box
                alignItems="center"
                display="flex"
                height="100%"
                justifyContent="center"
              >
                {
                  isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button variant="contained">
                      Purchase Licenses
                    </Button>
                  )
                }
              </Box>
            )
          }}
        />
      </Box>
    </Container>
  )
}